body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
}

.root {
  width: 100%;
}

.content-container {
  background-color: #f7f7f8;
  min-height: 100vh;
  margin-top: 65px;
  /* margin-left: 51px; */
  padding: 24px 64px;
  padding-bottom: 64px;
}

.sfp-media-wrapper {
  text-align: center;
  margin-bottom: 24px;
}

.sfp-question-separator {
  background-color: #f7f7f8;
  box-shadow: inset 0 1px 0 0 rgb(211 214 218 / 40%);
  height: 8px;
}

.sfp-question-text {
  flex: 1 1 auto;
  word-break: break-word;
}

.sfp-description {
  margin-top: 4px;
  color: #9298a3 !important;
  word-break: break-word;
  white-space: pre-wrap;
}

.sfp-media-wrapper {
  text-align: center;
  margin-bottom: 24px;
}

.survey-form-participant-client {
  position: absolute;
  width: 100%;
  height: 100%;
  /* background-color: #f4f5f7; */
  overflow-y: auto;
}
.form-background {
  position: fixed;
  left: 0;
  right: 0;
  top: 48px;
  width: 100%;
  height: calc(100vh - 48px);
  min-height: var(--inner-height, 100vh);
  background-color: var(--bg-color, #fff);
  z-index: -1;
  pointer-events: none;
  margin: 0 auto;
  max-width: 600px;
}
.bg-white {
  background-color: white;
}
.form-page-wrapper {
  width: 100%;
  position: relative;
}

.header-section {
  background-color: #fff;
  position: fixed;
  width: 100%;
  top: 0;
  max-width: 600px;
  z-index: 100;
}
.header-section-content {
  padding: 8px 16px;
}
.header--left {
  display: flex;
  align-items: center;
}
.logo {
  margin-right: 12px;
}
.logo > img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.oa-profile-name {
  color: #263147;
  font-weight: 700;
}

.form-page-body {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.form-page-wrapper .form-page-body > :first-child {
  flex: 1;
}
.question-progress-bar {
  position: fixed;
  top: 50px;
  width: 100%;
  max-width: 600px;
  color: #7d8391;
  text-overflow: ellipsis;
  overflow: hidden;
  z-index: 100;
  background-color: #fff;
  box-shadow: 0 1px 0 0 #e9eaec;
}
.question-progress-main {
  padding: 8px 16px;
}
.question-progress-bar-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 22px;
  transition: 0.15s;
}
.question-progress-bar--body {
  display: flex;
  align-items: center;
}
.question-progress-bar .question-progress-wrapper {
  flex: 1;
  margin-right: 8px;
}
.question-progress-bar .question-progress-desc {
  color: #7d8391;
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}
.question-wrapper {
  padding: 10px;
}
.main-wrapper {
  display: flex;
  min-height: 100%;
  margin: 0 auto;
  padding: 0 !important;
}
.group-term {
  display: flex;
}
.question-page-thankyou {
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  padding: 0 12px;
}
.question-page-navigation {
  /* background-color: #fff; */
  /* color: #263147ff; */
  font-size: 14px;
  line-height: 22px;
  padding: 0 12px;
}
.term-text-request {
  margin-top: 10px;
}
.term-text {
  padding: 8px 0;
}
.caption {
  font-size: 12px;
  line-height: 18px;
  /* color: #263147ff; */
  font-weight: 400;
  text-align: center;
  padding: 12px 0;
}
.question-term-error {
  color: #e5172f;
}

.question-page-footer {
  /* background-color: #fff; */
  color: #263147ff;
  font-size: 14px;
  line-height: 22px;
  padding: 0 12px;
  display: flex;
  margin-top: 15px;
}
.question-separator {
  background-color: #f7f7f8;
  box-shadow: inset 0 1px 0 0 rgb(211 214 218 / 40%);
  height: 8px;
}
.mr-2 {
  margin-right: 5px !important;
}
.ml-2 {
  margin-left: 5px !important;
}
.show-content {
  display: block;
}
.hidden-content {
  display: none !important;
}
.sfp-radio-container {
  cursor: pointer;
  display: inline-block;
  padding-top: 10px;
  vertical-align: top;
  word-break: break-word;
  width: 100%;
}

.sfp-radio-box {
  display: flex;
  align-items: center;
}

.sfp-raido-image-wrapper {
  /* background-color: #f7f7f8; */
  border: 1px solid #e9eaec;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  border-radius: 8px;
  width: 100%;
  /* position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-self: center; */
  /* margin-bottom: 8px; */
  height: 275.5px;
}
.question-thankyou {
  position: absolute;
  top: 0;
  z-index: 9999;
  min-height: 100vh;
  display: flex;
  background: white;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.question-term {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 9999;
  min-height: 100vh;
  display: flex;
  background: white;
  flex-direction: column;
  justify-content: space-between;
}

.question-pdpa-term {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 9999;
  min-height: 100vh;
  display: flex;
  background: white;
  flex-direction: column;
}

.question-term-content {
  position: absolute;
  top: 0;
  z-index: 9999;
  min-height: 100vh;
  display: flex;
  background: white;
  flex-direction: column;
  width: 100%;
}
.question-box {
  padding-top: 50px;
}
.question-box .question-wrapper:not(:last-child) {
  margin-bottom: 10px;
}
.header-back {
  background-color: #fff;
  color: #263147ff;
  /* min-width: 580px; */
  padding: 10px;
}
.btn-back {
  padding: 16px;
}
.tac-title {
  padding-bottom: 16px;
}
.question-page-detail-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 64px;
}
.question-page-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.birthdate-question {
  width: 100%;
  height: 40px;
  padding: 5px 14px;
  border-radius: 4px;
  border: 1px solid #c0c4d6;
  box-sizing: border-box;
  font-size: inherit;
  cursor: pointer;
}
.birthdate-question:hover {
  border: 1px solid #000000;
}
.birthdate-question:hover:focus {
  border: 1px solid #000000;
}
.birthdate-question:focus-visible {
  outline: #000000 auto 1px;
}

/* Custom Datetime Picker */
.rmdp-day.rmdp-today span {
  background-color: #676666 !important;
  color: #fff !important;
}
.rmdp-day span:hover {
  background-color: #c7c7c7 !important;
}
.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: #000000 !important;
  box-shadow: 0 0 3px #676666 !important;
  color: #fff !important;
}
.rmdp-week-day {
  color: #000000 !important;
}
.rmdp-header-values {
  color: #000;
  font-weight: 800;
}
.rmdp-arrow {
  border: solid #000000 !important;
  border-width: 0 2px 2px 0 !important;
}
.rmdp-right i {
  margin-right: 3px !important;
  transform: rotate(-45deg) !important;
  -webkit-transform: rotate(-45deg) !important;
}
.rmdp-left i {
  margin-left: 3px !important;
  transform: rotate(135deg) !important;
  -webkit-transform: rotate(135deg) !important;
}

.rmdp-arrow-container:hover {
  background-color: #000000 !important;
  box-shadow: 0 0 3px #8798ad;
}

.rmdp-arrow-container:hover .rmdp-arrow {
  border-color: white !important;
}

.error-page-container {
  height: 100vh;
  text-align: center;
  align-items: center;
  padding: 16px;
}

.error-text {
  color: #d32f2f;
  font-size: 14px;
  padding-top: 4px;
}

.swal2-container {
  z-index: 20000 !important;
}


.image-dropzone-wrapper {
  margin-bottom: 8px;
  border-radius: 4px;
  border: 1px solid #d3d6da;
  background-color: #f7f7f8;
  border-style: dashed;
  padding: 0 24px;
}

.image-dropzone-wrapper.error {
  border-color: #d32f2f;
}

.image-dropzone-wrapper > .data-input {
  width: 100%;
}

.image-dropzone-wrapper > .data-input > input {
  display: none;
}

.image-dropzone-wrapper > .data-preview {
  width: 100%;
  padding: 32px 0 32px 24px;
}


.image-dropzone-guideline-container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #9298a3;
  padding: 32px 24px;
}

.image-dropzone-guideline-container div.text-icon {
  display: flex;
  flex-direction: row;
}

.image-dropzone-guideline-container div.text-icon > svg {
  margin-right: 5px;
}


.design-note {
  color: #7d8391;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin-top: 8px;
}

.file-dropzone-guideline-container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #9298a3;
  padding: 32px 24px;
  border: 1px dashed #d0d5dd;
  border-radius: 8px;
}

.file-dropzone-guideline-container div.text-icon {
  display: flex;
  flex-direction: row;
}

.file-dropzone-guideline-container div.text-icon > svg {
  margin-right: 5px;
}

.dropzone-highlight-text {
  color: #3c41ff;
  cursor: pointer;
}
.dropzone-highlight-text:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  .sfp-raido-image-wrapper {
    height: 162px;
  }
}
